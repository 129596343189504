<template>
  <div>
    <h5 class="title is-5">Networks</h5>
    <b-field v-for="item in availableNetworks()" :key="'network-' + item.key">
      <b-checkbox
        v-model="networkContent"
        @input="handleNetworkChange(networkContent)"
        :native-value="item.key"
        :disabled="!item.enabled"
      >
        <span>{{ item.label }}</span>
      </b-checkbox>
    </b-field>

    <hr />

    <h5 class="title is-5">Details</h5>
    <b-field v-for="item in availablePayments()" :key="'payment-' + item.key">
      <b-checkbox
        v-model="paymentContent"
        @input="handlePaymentChange(paymentContent)"
        :native-value="item.key"
        :disabled="!item.enabled"
      >
        <span>{{ item.label }}</span>
      </b-checkbox>
    </b-field>

    <hr />

    <h5 class="title is-5">Benefits</h5>
    <b-field v-for="item in availableBenefits()" :key="'benefit-' + item.key">
      <b-checkbox
        v-model="benefitContent"
        @input="handleBenefitsChange(benefitContent)"
        :native-value="item.key"
      >
        <span>{{ item.label }}</span>
      </b-checkbox>
    </b-field>

    <hr />

    <h5 class="title is-5">Levels</h5>
    <b-field v-for="item in availableLevels" :key="'level-' + item">
      <b-radio
        v-model="levelContent"
        @input="$emit('input-level', levelContent)"
        :native-value="item"
      >
        <span>{{ item }}</span>
      </b-radio>
    </b-field>
  </div>
</template>

<script>
import { possiblePayments, possibleNetworks } from '../constants.js';

export default {
  name: 'InquiryDetailSideBar',
  props: {
    plan: Object,
    selectedNetworks: Array,
    selectedPayments: Array,
    selectedBenefits: Array,
    selectedLevel: String,
  },
  data() {
    return {
      networkContent: this.selectedNetworks,
      paymentContent: this.selectedPayments,
      benefitContent: this.selectedBenefits,
      levelContent: this.selectedLevel,
      availableLevels: ['All', 'Family', 'Individual'],
    };
  },
  methods: {
    // Return the networks types that are in use.
    availableNetworks() {
      const networks = possibleNetworks.map((n) => ({
        key: n.key,
        label: n.label,
        enabled: this.plan.benefit_groups.some(
          (group) =>
            group.benefits && group.benefits.some((benefit) => benefit.network_code === n.key)
        ),
      }));

      networks.unshift({ key: 'All', label: 'All', enabled: true });

      return networks;
    },

    // Return the payment types types that are in use.
    availablePayments() {
      const payments = possiblePayments.map((p) => ({
        key: p.key,
        label: p.label,
        enabled: this.plan.benefit_groups.some(
          (group) =>
            group.benefits && group.benefits.some((benefit) => benefit.amount_code === p.key)
        ),
      }));

      payments.unshift({ key: 'All', label: 'All', enabled: true });

      return payments;
    },

    // Return the benefit types types that are in use.
    // Benefit types are unknown so we don't iterate over
    // a known set like with networks and payments.
    availableBenefits() {
      const benefits = this.plan.benefit_groups
        .filter((group) => group.benefits && group.benefits.length !== 0)
        .map((group, i) => ({
          key: group.name,
          label: group.name,
          order: group.name?.toLowerCase() === 'health benefit plan coverage' ? 0 : i + 1, // ensure health benefit plan coverage is first
        }));

      // Reorder benefits
      benefits.sort((a, b) => a.order - b.order);

      benefits.unshift({ key: 'All', label: 'All' });

      return benefits;
    },
    handleNetworkChange(newValue) {
      if (this.allIsFirstItem(newValue)) {
        this.networkContent = this.networkContent.filter((i) => i != 'All');
      } else if (this.allIsLastItem(newValue)) {
        this.networkContent = this.networkContent.filter((i) => i == 'All');
      }

      this.$emit('input-network', this.networkContent);
    },
    handlePaymentChange(newValue) {
      if (this.allIsFirstItem(newValue)) {
        this.paymentContent = this.paymentContent.filter((i) => i != 'All');
      } else if (this.allIsLastItem(newValue)) {
        this.paymentContent = this.paymentContent.filter((i) => i == 'All');
      }

      this.$emit('input-payment', this.paymentContent);
    },
    handleBenefitsChange(newValue) {
      if (this.allIsFirstItem(newValue)) {
        this.benefitContent = this.benefitContent.filter((i) => i != 'All');
      } else if (this.allIsLastItem(newValue)) {
        this.benefitContent = this.benefitContent.filter((i) => i == 'All');
      }

      this.$emit('input-benefit', this.benefitContent);
    },
    allIsFirstItem(newValue) {
      return newValue.length > 1 && newValue[0] == 'All';
    },
    allIsLastItem(newValue) {
      return newValue.length > 1 && newValue[newValue.length - 1] == 'All';
    },
  },
};
</script>
